import type { WidgetBuilder } from '@wix/yoshi-flow-editor';
import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { EXPERIMENTS } from '@wix/communities-blog-client-common';
import { getWidgetPresets } from '../../editor/post-page-widget-presets';
import { postPageWidgetDesign } from './post-page-constants';

const setMobileGFPP = (controllerBuilder: WidgetBuilder) => {
  controllerBuilder
    .gfpp('mobile')
    .set('presets', { behavior: 'HIDE' })
    .set('layout', { behavior: 'HIDE' })
    .set('animation', { behavior: 'HIDE' })
    .set('design', { behavior: 'HIDE' });
};

export const getWidgetManifest: GetWidgetManifestFn = (
  widgetBuilder,
  _,
  flowAPI,
) => {
  widgetBuilder.set({
    displayName: 'Post Page',
    nickname: 'postPage',
  });

  const commentsEnabled = flowAPI.experiments.enabled(
    EXPERIMENTS.ENABLE_COMMENTS_INSTALLATION,
  );

  widgetBuilder.behavior().set({
    removable: false,
    duplicatable: false,
    toggleShowOnAllPagesEnabled: false,
    essential: {
      enabled: false,
    },
  });

  widgetBuilder.configureWidgetDesign((widgetDesign) => {
    widgetDesign.set(postPageWidgetDesign);
  });

  setMobileGFPP(widgetBuilder);

  widgetBuilder
    .gfpp()
    .set('presets', { behavior: 'HIDE' })
    .set('widgetPlugins', { behavior: commentsEnabled ? 'DEFAULT' : 'HIDE' });

  widgetBuilder.configureWidgetPresets((widgetPresetsBuilder) => {
    const presets = getWidgetPresets(flowAPI.environment.isSiteLanguageRTL);
    widgetPresetsBuilder.setPresets(presets);
  });
};
