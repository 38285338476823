import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { MA_APP_IDS } from '@wix/members-area-integration-kit';
import type {
  EditorPlatformApp,
  InitialAppData,
} from '@wix/platform-editor-sdk';
import { EditorReadyFn, AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { handleActionInternal } from './editor/handle-action-internal';
import { handleBlogInstallTimeout } from './editor/handle-blog-install-timeout';
import { configureMembersWidgets } from './editor/members-widgets-manifest-override';
import { overrideRelatedPostsWidgetManifest } from './editor/related-posts-manifest-override';
import type { EditorEvent } from './external/common/constants/events';
import { getAssetsUrl } from './external/common/services/editor-sdk';
import {
  handleEditorReady,
  handleGetAppManifest,
  handleOnEvent,
  untilTimeout,
} from './external/common/services/handlers';
import { getMetaSiteId } from './external/common/services/instance';
import maIntegrationKit from './external/common/services/ma-integration-kit';
import monitoring from './external/common/services/monitoring';
import translation from './external/common/services/translation';
import type { EditorAppContext } from './types/editor-app-context.type';
import { overridePostListWidgetManifest } from './editor/post-list-manifest-override';

const { translate } = translation;

const editorApp = maIntegrationKit.withMembersArea(createEditorApp(), {
  installAutomatically: false,
  installAppsAutomatically: false,
  membersAreaApps: [
    MA_APP_IDS.ABOUT,
    MA_APP_IDS.FOLLOWERS,
    MA_APP_IDS.NOTIFICATIONS,
    MA_APP_IDS.SETTINGS,
  ],
});

interface BlogEditorAppInterface
  extends Omit<
    EditorPlatformApp<AppManifestBuilder>,
    'editorReady' | 'handleAction'
  > {
  editorReady: EditorReadyFn;
}

export function createEditorApp(): Required<BlogEditorAppInterface> {
  let context: EditorAppContext;

  return {
    editorReady: async (sdk, appToken, options, flowAPI) => {
      try {
        const instance = options.initialAppData.instance;
        const instanceId = options.initialAppData.instanceId;
        const metaSiteId = getMetaSiteId(instance)!;
        const origin = options.origin.type;

        monitoring.init({
          fedopsLoggerFactory: options.essentials.fedopsLoggerFactory,
          metaSiteId,
          instanceId,
        });

        type OriginInfo = { installMembersArea?: boolean } | undefined;

        context = {
          sdk,
          appToken,
          isADI: origin === 'ADI' || origin === 'ADI_MA',
          isClassicEditor: origin === 'CLASSIC',
          isEditorX: origin === 'RESPONSIVE',
          installMembersArea: (options.origin.info as OriginInfo)
            ?.installMembersArea,
          instance,
          essentials: options.essentials,
          assetsUrl: getAssetsUrl(options),
          biData: options.biData,
          flowAPI,
          silentInstallation: options.silentInstallation ?? false,
          origin: options.origin,
          editorType: origin,
        };

        return monitoring.toMonitored(
          'editor-ready',
          monitoring.toMonitored(
            'editor-ready-duration',
            (async () => {
              if (!context) {
                return;
              }

              await handleEditorReady({
                context,
                firstInstall: options.firstInstall,
              });

              console.log(
                '[Blog] Platform app is up',
                ...(process.env.NODE_ENV === 'development'
                  ? [{ options, context }]
                  : []),
              );
            })(),
            true,
            120000,
          ),
        );
      } catch (error) {
        console.log('BLOG INSTALLATION FAILED WITH ERROR:', error);
      }
    },
    onEvent: (event) => {
      if (!context) {
        return;
      }

      return handleOnEvent(event as EditorEvent, context);
    },
    getAppManifest: async (options) => {
      if (!context) {
        return {};
      }

      const [appManifest, widgetStageData] = await Promise.all([
        handleGetAppManifest(context),
        fetchWidgetsStageData({
          initialAppData: options as unknown as InitialAppData,
          editorSDK: context.sdk,
          translateFunc: translate,
        }),
      ]);

      for (const key in widgetStageData.controllersStageData) {
        if (
          widgetStageData.controllersStageData[key].default?.connections?.['*']
        ) {
          delete widgetStageData.controllersStageData[key].default
            ?.connections?.['*'];
        }
      }

      overrideRelatedPostsWidgetManifest(widgetStageData.controllersStageData);
      overridePostListWidgetManifest(widgetStageData.controllersStageData);

      configureMembersWidgets(options.appManifestBuilder as AppManifestBuilder);

      return options.appManifestBuilder
        .withJsonManifest({ ...appManifest, ...widgetStageData })
        .build();
    },
    exports: (editorSDK) => ({
      editor: {
        appInstalled: async (data) => {
          const handleActionTimedOut = await untilTimeout(
            async () => {
              if (context) {
                await handleActionInternal({
                  action: {
                    type: 'appInstalled',
                    payload: {
                      appDefinitionId: data.appDefinitionId,
                    },
                  },
                  editorSDK,
                  context,
                });
              }
            },
            `handleActionInternal on appInstalled (appDefinitionId: ${data.appDefinitionId}) is stuck`,
            60000,
          );

          if (handleActionTimedOut) {
            await handleBlogInstallTimeout(context);
          }
        },
        getSelectedWidgetDesignPresetId: async ({ widgetRef }) => {
          const preset = await editorSDK.application.appStudioWidgets.getPreset(
            '',
            {
              componentRef: widgetRef,
            },
          );
          return preset.style;
        },
      },
    }),
  };
}

export const editorReady = editorApp.editorReady;
export const onEvent = editorApp.onEvent;
export const getAppManifest = editorApp.getAppManifest;
export const exports = editorApp.exports;
